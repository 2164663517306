import Vue from 'vue'
import Vuex from 'vuex'

import plugin from '@/Store/plugin'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    plugin
  }
})

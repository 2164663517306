<template>
  <div id="app">
    <template>
      <router-view></router-view>
      <vue-progress-bar id="loading-bar"></vue-progress-bar>
    </template>
    <!-- Global toastr component -->
    <vue-toastr ref="toastr"></vue-toastr>
  </div>
</template>

<script>
import Toastr from 'vue-toastr'

export default {
  name: 'app',
  data: function () {
    return {
      pageLoaded: false // page loaded default false
    }
  },
  components: {
    'vue-toastr': Toastr
  }
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}

.invalid {
  .form-control {
    border-color: #f74a55;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.valid {
  .form-control {
    border-color: #4caf50;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }
  }
}

.form-group.warn {
  .form-control {
    border-color: #ff9800;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
    }
  }
}
</style>

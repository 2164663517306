var render, staticRenderFns
import script from "./VueSnapBar.vue?vue&type=script&lang=js&"
export * from "./VueSnapBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/paygateuk/paygate-3/ids/ids/Client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('018ad6f9')) {
      api.createRecord('018ad6f9', component.options)
    } else {
      api.reload('018ad6f9', component.options)
    }
    
  }
}
component.options.__file = "src/Components/Snapbar/VueSnapBar.vue"
export default component.exports
// Imports
import Vue from 'vue'
import Router from 'vue-router'

// Main
const Full = () => import(/* webpackChunkName: "main" */ '@/Components/Full')

// Login
const Login = () => import(/* webpackChunkName: "login" */'@/Components/Login/Login')

// 2FA Codes
const VerifyCode = () => import(/* webpackChunkName: "code" */'@/Components/VerifyCode/VerifyCode')
const Setup2FA = () => import(/* webpackChunkName: "code" */'@/Components/Setup2FA/Setup2FA')

// Passwords
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */'@/Components/ForgotPassword/ForgotPassword')
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */'@/Components/ResetPassword/ResetPassword')
const ChangePassword = () => import(/* webpackChunkName: "change-password" */'@/Components/ChangePassword/ChangePassword')

// Log out
const LoggedOut = () => import(/* webpackChunkName: "log-out" */'@/Components/LoggedOut/LoggedOut')

const ConfirmEmail = () => import(/* webpackChunkName: "confirm-email" */'@/Components/ConfirmEmail/ConfirmEmail')

const props = (route) => {
  return { query: { ...route.query }, ...route.params }
}

Vue.use(Router)
// define your routes here
export default new Router({
  routes: [
    {
      path: '/',
      component: Full,
      props: { displayHeader: false, isIDS: true },
      children: [
        {
          name: 'Login',
          path: '/Login',
          component: Login,
          meta: {
            anon: true
          },
          props: (route) => {
            return { query: { ...route.query, ...route.params } }
          }
        },
        {
          name: 'VerifyCode',
          path: '/VerifyCode',
          component: VerifyCode,
          meta: {
            breadcrumb: 'Verify Code',
            anon: true
          },
          props
        },
        {
          name: 'ForgotPassword',
          path: '/ForgotPassword',
          component: ForgotPassword,
          meta: {
            breadcrumb: 'Forgot Password',
            anon: true
          },
          props
        },
        {
          name: 'ResetPassword',
          path: '/ResetPassword',
          component: ResetPassword,
          meta: {
            breadcrumb: 'Reset Password',
            anon: true
          },
          props
        },
        {
          name: 'ConfirmEmail',
          path: '/ConfirmEmail',
          component: ConfirmEmail,
          meta: {
            breadcrumb: 'Confirm Email',
            anon: true
          },
          props
        },
        {
          name: 'ChangePassword',
          path: '/ChangePassword',
          component: ChangePassword,
          meta: {
            breadcrumb: 'Change Password',
            anon: true
          },
          props
        },
        {
          name: 'Setup2FA',
          path: '/setup',
          component: Setup2FA,
          meta: {
            breadcrumb: 'Setup 2FA',
            anon: true
          },
          props
        },
        {
          path: '/LoggedOut',
          component: LoggedOut,
          meta: {
            breadcrumb: 'Logged Out',
            anon: true
          }
        }
      ]
    }
  ],
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

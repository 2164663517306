// Import the main application files
import Vue from 'vue'
import App from '@/App'
import Toastr from 'vue-toastr'
import Vuelidate from 'vuelidate'
import SnapBar from '@/Components/Snapbar/VueSnapBar'
import VueProgressBar from 'vue-progressbar'
import { ModalPlugin } from 'bootstrap-vue'

// Import Axios and router
import axios from 'axios'
import router from '@/router'

import Loading from '@/Components/Loading'
import '@/Directives/focus.js'

// Import vuex
import store from '@/Store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Add Vue usings
Vue.use(Toastr)
Vue.use(Vuelidate)
Vue.use(SnapBar)
Vue.use(VueProgressBar, {
  color: '#c7027f',
  failedColor: '#f74a55',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 500
  },
  location: 'top',
  inverse: false,
  position: 'fixed',
  autoFinish: false
})
Vue.use(ModalPlugin)
// Add Vue global components
Vue.component('loading', Loading)

Vue.config.productionTip = false
Vue.config.performance = true

// Setup Axios
axios.defaults.baseURL = `${process.env.VUE_APP_ROOT_API}/identity/api/`
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.withCredentials = true

// Get CSFR token
axios.get('Home/GetToken').then(function (response) {
  axios.defaults.headers.common['X-XSRF-TOKEN'] = response.data.token
})

export const vueInstance = new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App,
    'vue-toastr': Toastr
  },
  beforeMount: function () {
    // toastr global default settings
    this.$toastr.defaultPosition = 'toast-bottom-right'
    this.$toastr.defaultTimeout = 5000
  }
})

axios.interceptors.request.use(
  async (config) => {
    if (vueInstance) {
      if (config.showload) {
        vueInstance.$Progress.start()
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    if (vueInstance && response.config.showload) {
      vueInstance.$Progress.finish()
    }
    return response
  },
  (error) => {
    if (error.response) {
      if (vueInstance && error.response.config.showload) {
        vueInstance.$Progress.fail()
      }

      if (error.response.config.showerror) {
        if (error.response.data) {
          if (error.response.status === 409) {
            vueInstance.$snapbar.e(error.response.data)
          } else {
            vueInstance.$snapbar.e(error.response.data, error.response.statusText)
          }
        } else {
          vueInstance.$snapbar.e(error.response.config.errormessage, 'Error')
        }
      }
    }

    return Promise.reject(error)
  }
)

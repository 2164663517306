var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.shown
          ? _c("div", { staticClass: "snap-container" }, [
              _c("div", { staticClass: "snap-box", class: _vm.type }, [
                _c(
                  "span",
                  {
                    staticClass: "snap-wrapper",
                    class: { expand: _vm.notify }
                  },
                  [
                    _c("span", { staticClass: "snap-logo" }),
                    !_vm.html
                      ? _c("span", { staticClass: "snap-message" }, [
                          _vm._v(_vm._s(_vm.message))
                        ])
                      : _c("span", {
                          staticClass: "snap-message",
                          domProps: { innerHTML: _vm._s(_vm.message) }
                        })
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "snap-close", on: { click: _vm.hide } },
                  [_c("span", { staticClass: "fas fa-times" })]
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
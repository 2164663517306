<template>
  <transition name="loading-fade" mode="out-in">
    <div v-if="loading">
      <div class="loader-overlay">
        <div class="loader">
          <img class="spinner" src="/img/sharedimages/CorvidLogo/New/PAYGATE LOGO_CMYK.svg" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  name: 'loading',
  props: ['loading']
}
</script>
<style lang="scss" scoped>
.loader-overlay {
  background-color: rgba(217, 217, 217, 0.831);
  z-index: 999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9em;
  height: 9em;
  margin-top: -4.5em; /* Half the height */
  margin-left: -4.5em; /* Half the width */
  z-index: 999;
}

.loading-fade-enter-active,
.loading-fade-leave-active {
  transition: none 0.5s ease-in-out;
}
.loading-fade-leave-to {
  .loader-overlay {
    opacity: 0;
  }
}

.loading-fade-enter-to,
.loading-fade-leave {
  .loader-overlay {
    opacity: 1;
  }
}
</style>
